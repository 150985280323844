/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a",
    h2: "h2",
    div: "div",
    code: "code",
    pre: "pre",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "From v1 we are providing tags for embedding Signatu apps in your site. You can also use the React components\nfor more fine-grained control."), "\n", React.createElement(_components.p, null, "Application we support by embedding tags in your site:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/docs/api/v1/sdk/js/cookie-banner"
  }, "Cookie Banner")), "\n"), "\n", React.createElement(_components.h2, {
    id: "customizing-texts",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#customizing-texts",
    "aria-label": "customizing texts permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Customizing texts"), "\n", React.createElement(_components.p, null, "You can customize many of the texts in the UI. Refer to ", React.createElement(_components.a, {
    href: "/docs/api/v1/sdk/customization/texts"
  }, "Customizing Texts"), " for more details."), "\n", React.createElement(_components.h2, {
    id: "consent-events",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#consent-events",
    "aria-label": "consent events permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Consent Events"), "\n", React.createElement(_components.p, null, "Consent events are posted to the window the control(s) are embedded in."), "\n", React.createElement(_components.p, null, "The format of the events are as follows:"), "\n", React.createElement(_components.p, null, "To listen to these events add a listener on the ", React.createElement(_components.code, null, "window"), ". The event will be of type ", React.createElement(_components.code, null, "object"), " in the ", React.createElement(_components.code, null, "data"), " property:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "function isConsentMessage(msg) {\n    return msg && msg.data && typeof msg.data === 'object' && msg.data.type === 'signatu-consent-message'\n}\n\nwindow.addEventListener('message', (msg) => {\n    if (isConsentMessage(msg)) {\n        this.handleConsentEvent(msg.data.consent) // <= consent payload\n    }\n})\n")), "\n", React.createElement(_components.p, null, "The signatu SDK emits the following events:"), "\n", React.createElement(_components.p, null, "| ", React.createElement(_components.code, null, "event.type"), "            | Description                                                                                   |\n| ----------------------- | --------------------------------------------------------------------------------------------- |\n| signatu-consent-message | The consent event as returned from signatu. The event is available in the ", React.createElement(_components.code, null, "consent"), " property. |"), "\n", React.createElement(_components.h3, {
    id: "example-event",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#example-event",
    "aria-label": "example event permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Example event"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n    \"type\": \"signatu-consent-message\",\n    \"consent\": {\n        \"subject\": \"https://signatu.com/api/v1/ns/cookie/f8d7cad7\",\n        \"issuer\": \"https://signatu.com/api/v1/datacontrollers/84\",\n        \"target\": \"https://signatu.com/api/v1/policies/1\",\n        \"action\": false,\n        \"authenticated\": null,\n        \"source\": \"https://demo.signatu.com/\",\n        \"token\": \"RRTSJ8\",\n        \"receipt\": \"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUz...\",\n        \"checksums\": {\n            \"target\": {\n                \"sha256\": \"c80853526596211f30d973d211d...bfb22\"\n            }\n        },\n        \"id\": 334,\n        \"createdAt\": \"2016-11-15T13:37:02.634Z\",\n        \"updatedAt\": \"2016-11-15T13:37:02.639Z\"\n    }\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
